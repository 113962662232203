
import { Component, Vue } from 'vue-property-decorator';
import VsFlex from './vs-flex.vue';

@Component({ components: { VsFlex } })
class VsRow extends Vue {

}

export default VsRow;
