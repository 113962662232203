
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsAdminToolbarSelectItem extends Vue {

    @Prop({ type: String, required: true })
    private readonly title!: string;

    @Prop({ type: String })
    private readonly subtitle?: string;

    @Prop({ type: Boolean, default: false })
    private readonly selected!: boolean;
}

export default VsAdminToolbarSelectItem;
