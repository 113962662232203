
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ })
class VsAdminToolbarActionItem extends Vue{

    @Prop({ type: String, required: true })
    private readonly icon!: string;

    @Prop({ type: String, required: true })
    private readonly text!: string;

    @Prop({ type: Boolean, default: true })
    private readonly hasInteraction!: boolean;

    @Prop({ type: Boolean, default: false })
    private readonly showArrow!: boolean;
}

export default VsAdminToolbarActionItem;
