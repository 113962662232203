
import { Component, Vue } from 'vue-property-decorator';
import { VsModal } from '../vs-modal';
import { VsIcon } from '../../controls/vs-icon';
import { VsOption } from './index';

@Component({ components: { VsModal, VsIcon } })
class VsAlert extends Vue {
    
    public options: VsOption[] | null = null;

    public icon: string = 'alert';
    public title: string = '';
    public message: string = '';
    public isOpened: boolean = false;
    public detail: string = '';

    public showAlert(title: string, message: string, options: VsOption[] | null = null, icon: string = 'alert') {
        this.options = options;
        this.icon = icon;
        this.title = title;
        this.message = message;

        this.isOpened = true;
    }

    public displayAlert(options: { title: string; message: string; options?: VsOption[]; icon?: string; detail?: string}) {
        this.options = options.options || null;
        this.icon = options.icon || 'alert';
        this.title = options.title;
        this.message = options.message;
        this.detail = options.detail || '';

        this.isOpened = true;
    }

    public loadAction(action?: () => void) {
        this.isOpened = false;
        setTimeout(() => {
            if(action != null) {
                action();
            }
        }, 300);
    }
}

export default VsAlert;
