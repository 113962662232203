
import { Component, Vue, Prop } from 'vue-property-decorator';
import VsSideView from '../vs-side-view/vs-side-view.vue';

@Component({ components: { VsSideView } })
class VsAgendamentos extends Vue {

    @Prop({ type: Date, required: true })
    private date!: Date;
    
    @Prop({ type: String, default: 'gray' })
    private calendarColor!: Date;

    @Prop({ type: Boolean, default: true })
    private canChooseDate!: boolean;

    private dateTemp: Date = this.date;
    private calendarOpened: boolean = false;

    private get dateString(): string {
        const months = ['janeiro', 'fevereiro', 'mar�o', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
        return this.date.getDate() + ' / ' + months[this.date.getMonth()] + ' / ' + this.date.getFullYear();
    }

    private openCalendar(){
        this.calendarOpened = !this.calendarOpened;
        if (this.calendarOpened)
            this.$emit('calendarOpened');
    }
}

export default VsAgendamentos;
