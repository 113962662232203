
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({ })
class VsAdminToolbar extends Vue {

    @Prop({ type: Boolean, default: false })
    private disableDropdown!: boolean;

    @Prop({ type: Boolean, default: false })
    private showVersion!: boolean;

    @Prop({ type: String, default: '1.0.0' })
    private appVersion!: string;

    @Prop({ type: String, default: '' })
    private faqLink!: string;

    @Prop({ type: String, required: true })
    private userName!: string;

    @Prop({ type: String })
    private avatar?: string;

    @Prop({ type: String, default: 'Logout' })
    private textLogout!: string;
    
    private menuOpened: boolean = false;

    private get selectedOption() {
        const selects = this.$slots.select;
        if(selects != null) {
            const selected = selects.find(el => (el.componentOptions!.propsData as any).selected);
            if(selected != null) {
                return (selected as any).componentOptions.propsData.title;
            }
            else {
                return 'Selecione uma op��o';
            }
        }
        return null;
    }

    private openFaqLink(){
        this.$emit('faqClick');
        window.open(this.faqLink, '_blank');
    }
}

export default VsAdminToolbar;
