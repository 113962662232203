
import { Component, Vue } from 'vue-property-decorator';
import VsFlex from './vs-flex.vue';

@Component({ components: { VsFlex } })
class VsColumn extends Vue {

}

export default VsColumn;
